
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import moment from "moment";
import { 
  Line as LineChart,
  Pie as PieChart, 
} from "@ant-design/plots";
import { 
  Row, Col, Modal,
  Tooltip, Button,
} from "components/common";
import { callGetApiWithAuth } from 'utils/api';
import { asPrice } from 'utils/text';
import { ReactComponent as NophotoIcon } from 'assets/nophoto.svg';

export default function TraderLbdPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onGetTableData = ({ data }) => {
    setTableData(data)
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = () => {
    setIsLoading(true)
    callGetApiWithAuth(`akashx/leaderboard/list_by_traders`,
      onGetTableData,
      onFailTableData,
    )
  }
  const handleSearch = () => {
    loadTableData()
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        {tableData.map(el => 
          <Col xs={24} sm={6}>
            <EducatorCard data={el} />
          </Col>
        )}
      </Row>
      <GlobalStyle />
    </Wrapper>
  )
}

const EducatorCard = ({ data }) => {
  const myUser = useSelector(state => state.auth.user)

  return (
    <div className='edu-card'>
      <div className='photo-wrap'>
        <img src={data.educator.image||NophotoIcon} />
      </div>
      <div className='edu-wrap'>
        <p className='name-span'>
          {data.educator.first_name}&nbsp;
          {data.educator.last_name}
        </p>
        <Tooltip placement="topLeft"
          title={
            <EduDescTipWrap>
              <div 
                dangerouslySetInnerHTML={{__html: data.educator.description}} 
              />
            </EduDescTipWrap>
            
          }
        >
          <p className='desc-span'
            dangerouslySetInnerHTML={{
              __html: data.educator.description
            }}
          />
        </Tooltip>
      </div>
      <div className='btm-wrap'>
        <StatBtn data={data} />
      </div>
      {/* <div className='btm-wrap'>
        <div className='val-wrap'>
          <p className='val-lbl'>
            Total Trades
          </p>
          <p className='val-span'>
            {data.all_count}
          </p>
        </div>
        <div className='val-wrap'>
          <p className='val-lbl'>
            Win Ratio
          </p>
          <p className='val-span'>
            {data.won_ratio}%
          </p>
        </div>
        <div className='val-wrap'>
          <p className='val-lbl'>
            Total Profit
          </p>
          <p className='val-span'>
            {asPrice(data.profit)}
          </p>
        </div>
        <div className='val-wrap'>
          <p className='val-lbl'>
            Total Growth
          </p>
          <p className='val-span'>
            {data.growth}%
          </p>
        </div>
      </div> */}
    </div>
  )
}

const StatBtn = ({ data }) => {
  const [modalMode, setModalMode] = useState(undefined)
  const [statData, setStatData] = useState(undefined)

  useEffect(() => {
    callGetApiWithAuth(
      `akashx/leaderboard/${data.educator.id}/trader_stat`,
      ({ data }) => {
        setStatData(data)
      },
      () => {
      }
    )
  }, [])

  return (
    <>
      <Button onClick={() => setModalMode('stat')}>
        View Trade History
      </Button>
      {(modalMode==='stat' && statData) && 
        <Modal 
          width={1200}
          onCancel={() => setModalMode(undefined)}
          className={'stat-modal'}
        >
          <StatWrap>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <h4>Performance</h4>
              </Col>
              <Col xs={24}>
                <LineChart data={statData.daily_list}
                  xField='date'
                  yField='result'
                  legend={false}
                  height={250}
                  color={'#FFC427'}
                  xAxis={{
                    label: {
                      formatter: (v) => (
                        moment(v).format('DD-MM-YY')
                      )
                    }
                  }}
                  yAxis={{
                    label: {
                      formatter: (v) => (
                        v+'%'
                      )
                    }
                  }}
                />
              </Col>
              <Col xs={24}>
                &nbsp;
              </Col>
              <Col xs={24}>
                <h4>Statistics</h4>
              </Col>
              <Col xs={24} sm={8}>
                <div className='stat-line'>
                  <label>
                    Total Profit:
                  </label>
                  <span>
                    {statData.total_profit}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Daily Profit:
                  </label>
                  <span>
                    {statData.daily_profit}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Weekly Profit:
                  </label>
                  <span>
                    {statData.weekly_profit}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Monthly Profit:
                  </label>
                  <span>
                    {statData.monthly_profit}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Avg Win:
                  </label>
                  <span>
                    {statData.avg_win}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Avg Loss:
                  </label>
                  <span>
                    {statData.avg_loss}%
                  </span>
                </div>
                {/* <div className='stat-line'>
                  <label>
                    # Days Traded:
                  </label>
                  <span>
                    {statData.traded_days}
                  </span>
                </div> */}
              </Col>
              <Col xs={24} sm={8}>
                <div className='stat-line'>
                  <label>
                    # Trades:
                  </label>
                  <span>
                    {statData.total_trades}
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    # Trades Won:
                  </label>
                  <span>
                    {statData.won_trades}
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    # Trades Lost:
                  </label>
                  <span>
                    {statData.lost_trades}
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    # Long Trades:
                  </label>
                  <span>
                    {statData.long_trades}
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    # Short Trades:
                  </label>
                  <span>
                    {statData.short_trades}
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    # Open Trades:
                  </label>
                  <span>
                    {statData.open_trades}
                  </span>
                </div>
              </Col>
              <Col xs={24} sm={8}>
                <div className='stat-line'>
                  <label>
                    Best Trade:
                  </label>
                  <span>
                    {statData.best_trade}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Worst Trade:
                  </label>
                  <span>
                    {statData.worst_trade}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Won Trades Profit:
                  </label>
                  <span>
                    {statData.won_trade_profit}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Lost Trades Profit:
                  </label>
                  <span>
                    {statData.lost_trade_profit}%
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Winning Streak:
                  </label>
                  <span>
                    {statData.winning_streak} trades
                  </span>
                </div>
                <div className='stat-line'>
                  <label>
                    Lost Streak:
                  </label>
                  <span>
                    {statData.losing_streak} trades
                  </span>
                </div>
              </Col>
              <Col xs={24} sm={24}>
                <h4>Trade Distribution</h4>
              </Col>
              <Col xs={24} sm={24}>
                <div className='pie-cnt'>
                  <PieChart 
                    data={statData.dist_list}
                    angleField='value'
                    colorField='name'
                    radius={0.8}
                    label={{
                      type: 'outer',
                    }}
                  />
                </div>
              </Col>
            </Row>
          </StatWrap>
        </Modal>
      }
    </>
  )
}

const Wrapper = styled.div`
  .edu-card {
    padding: 25px 20px 90px 20px;
    background-color: ${props=>props.theme.palette.cardBg};
    border-radius: 10px;
    height: 100%;
    .photo-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name-span {
      margin-top: 20px;
      color: ${props=>props.theme.palette.text};
      font-size: 18px;
    }
    .desc-span {
      color: ${props=>props.theme.palette.primary};
      margin: 0;
      font-size: 14px;
      line-height: 1.5em;
      overflow: hidden;
      p {
        margin: 0;
      }
    }
    .btm-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: absolute;
      left: 30px;
      bottom: 30px;
      width: calc(100% - 60px);
      .ant-btn {
        width: 100%;
      }
    }
    .val-wrap {
      width: calc(50% - 12px);
    }
    .val-lbl {
      color: ${props=>props.theme.palette.secondaryText};
      margin: 12px 0 0 0;
    }
    .val-span {
      color: ${props=>props.theme.palette.text};
      margin: 0;
    }
  }
`

const EduDescTipWrap = styled.div`
  border-radius: 4x;
  border: 1px solid ${props=>props.theme.palette.modalBorder};
  background: ${props=>props.theme.palette.modalBg};
  color: ${props=>props.theme.palette.text};
  width: 320px;
  padding: 20px;
  p {
    margin-bottom: 0;
  }
`

const StatWrap = styled.div`
  h4 {
    color: ${props=>props.theme.palette.text};
    font-size: 18px;
    margin-bottom: 0;
  }
  .stat-line {
    display: flex;
    justify-content: space-between;
    label {
      width: calc(100% - 140px);
      display: block;
    }
    span {
      width: 130px;
      display: block;
    }
  }
  .pie-cnt {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const GlobalStyle = createGlobalStyle`
  .stat-modal .ant-modal-content {
    border: 1px solid ${props=>props.theme.palette.modalBorder};
    background: ${props=>props.theme.palette.modalBg};
    color: ${props=>props.theme.palette.text};
  }
`
