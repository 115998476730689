import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Row,
  Col,
  DisclaimerBox,
  CountryFlag,
  RingProgressChart,
  SuccessNotification,
  InfoNotification,
  Progress,
} from "components/common";
import { varLabel } from "common/var";
import { asKNumber, asDate, asCurrencyPrice } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as Stat1Icon } from "assets/stat1.svg";
import { ReactComponent as Stat2Icon } from "assets/stat2.svg";
import { ReactComponent as Stat3Icon } from "assets/stat3.svg";
import { ReactComponent as Stat4Icon } from "assets/stat4.svg";
import NoPhotoImg from "assets/nophoto.svg";
import NoPhoto2Img from "assets/nophoto2.png";
import WhNoPhoto2Img from "assets/nophoto2_wh.png";
import AppStoreImg from "assets/appStore.png";
import GooglePlayImg from "assets/googlePlay.png";
import AppStoreWhImg from "assets/appStore_wh.png";
import GooglePlayWhImg from "assets/googlePlay_wh.png";
import CheckedImg from "assets/checked-circle.png";
import LinkIcon from "assets/link.svg";
import Banner1Img from "assets/academy.jpg";
import Banner2Img from "assets/live-sessions.jpg";
import Banner3Img from "assets/market-research.jpg";
import Banner4Img from "assets/trading-alerts.jpg";
import Banner5Img from "assets/trading-scanners.jpg";
import MobileBanner1Img from "assets/m_academy.jpg";
import MobileBanner2Img from "assets/m_live-education.jpg";
import MobileBanner3Img from "assets/m_market-research.jpg";
import MobileBanner4Img from "assets/m_trading-alerts.jpg";
import MobileBanner5Img from "assets/m_trading-scanners.jpg";
import moment from "moment";

export default function HomePage() {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const theme = useSelector((state) => state.ui.theme);
  const [statData, setStatData] = useState({
    total_trades: "-",
    total_trades_won: "-",
    total_profit: "-",
    growth: "-",
    balance: "-",
    currency: "USD",
    active_taps: 0,
    active_alerts: 0,
  });
  const [qualUsers, setQualUsers] = useState([]);
  const [educators, setEducators] = useState([]);

  const [stats, setStats] = useState({
    total_order_amount: 0,
    qualified_amount: 0,
    pe_count: 0,
  });

  const onGetStatData = ({ data }) => {
    setStatData({
      ...statData,
      ...data,
    });
  };
  const getStatData = () => {
    callGetApiWithAuth(`akashx/home/stat`, onGetStatData);
  };

  const onGetFree3Data = (res) => {
    // setQualUsers([...res.data.data]);

    setStats({
      total_order_amount: res.data.stats.total_order_amount,
      qualified_amount: res.data.stats.qualified_amount,
      pe_count: res.data.data.length,
    });
  };
  const getFree3Data = () => {
    const params = {
      date: moment().format("YYYY-MM"),
    };

    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");

    callGetApiWithAuth(`akashx/home/three_free_track_new?${q}`, onGetFree3Data);
  };

  const onGetEducators = ({ data }) => {
    setEducators(data);
    $(".owl-educator").owlCarousel({
      loop: true,
      rewind: true,
      autoplay: true,
      slideTransition: "linear",
      autoplayHoverPause: false,
      items: 6,
      margin: 18,
      responsive: {
        0: {
          items: 1,
          margin: 0,
        },
        600: {
          items: 2,
          margin: 8,
        },
        1200: {
          items: 4,
        },
        1800: {
          items: 6,
        },
      },
    });
  };
  const getEducators = () => {
    callGetApiWithAuth(`akashx/home/featured_educators`, onGetEducators);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://www.akashx.com/${myUser?.username}`);
    SuccessNotification("Copied on clipboard");
  };

  useEffect(() => {
    getStatData();
    getFree3Data();
    getEducators();
    dispatch({
      type: "auth.RELOAD",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      $(".owl-banner").owlCarousel({
        loop: true,
        autoplay: true,
        slideTransition: "linear",
        autoplayHoverPause: false,
        dots: true,
        dotsEach: true,
        items: 1,
      });
    }, [1000]);
  }, []);

  return (
    <Wrapper>
      <Row gutter={[20, 24]}>
        {/* <Col xs={24} sm={12} lg={12} xl={12} xxl={6}>
          <div className="stat-card yellow-card">
            <div className="card-img">
              <Stat1Icon />
            </div>
            <div className="card-desc">
              <h4>Total Trades</h4>
              <p>{asKNumber(statData.total_trades)}</p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={12} xl={12} xxl={6}>
          <div className="stat-card">
            <div className="card-img">
              <Stat2Icon />
            </div>
            <div className="card-desc">
              <h4>Total Trades Won</h4>
              <p>{asKNumber(statData.total_trades_won)}</p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={12} xl={12} xxl={6}>
          <div className="stat-card">
            <div className="card-img">
              <Stat3Icon />
            </div>
            <div className="card-desc">
              <h4>Profit</h4>
              <p>{asCurrencyPrice(statData.total_profit, statData.currency)}</p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={12} xl={12} xxl={6}>
          <div className="stat-card">
            <div className="card-img">
              <Stat4Icon />
            </div>
            <div className="card-desc">
              <h4>Growth</h4>
              <p>
                {isNaN(statData.growth)
                ? statData.growth
                : `${Math.round(statData.growth * 10) / 10}%`}
              </p>
            </div>
          </div>
        </Col> */}
        <Col xs={24}>
          <div className="owl-carousel owl-theme owl-banner">
            <div className="item">
              <img className="banner-img" src={Banner1Img} />
              <img className="mobile-banner-img" src={MobileBanner1Img} />
            </div>
            <div className="item">
              <img className="banner-img" src={Banner2Img} />
              <img className="mobile-banner-img" src={MobileBanner2Img} />
            </div>
            <div className="item">
              <img className="banner-img" src={Banner3Img} />
              <img className="mobile-banner-img" src={MobileBanner3Img} />
            </div>
            <div className="item">
              <img className="banner-img" src={Banner4Img} />
              <img className="mobile-banner-img" src={MobileBanner4Img} />
            </div>
            <div className="item">
              <img className="banner-img" src={Banner5Img} />
              <img className="mobile-banner-img" src={MobileBanner5Img} />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} xxl={7}>
          <div className="mt5-card">
            <div className="card-upper">
              <div className="photo-wrap">
                <img src={myUser?.image || NoPhotoImg} />
              </div>
              <div className="title-wrap">
                <h4>
                  {myUser?.first_name}&nbsp;
                  {myUser?.last_name}
                </h4>
                <h5>{myUser?.username}</h5>
                <p>
                  ID:&nbsp;
                  {myUser?.uuid}
                </p>
              </div>
            </div>
            <div className="card-bottom">
              <p>
                <label className="card-label">Trading Account:</label>
                <span
                  className={`algo-span ${myUser &&
                      myUser.algo_setting &&
                      myUser.algo_setting.status * 1 == 1
                      ? "connected-span"
                      : "inactive-span"
                    }`}
                >
                  {myUser && myUser.algo_setting ? (
                    <>
                      {myUser.algo_setting.status * 1 == 1
                        ? "Connected"
                        : myUser.algo_setting.status * 1 == 2
                          ? "Pending"
                          : myUser.algo_setting.status * 1 == 7
                            ? "Connecting"
                            : myUser.algo_setting.status * 1 == 8 ||
                              myUser.algo_setting.status * 1 == 4
                              ? "Failed"
                              : myUser.algo_setting.status * 1 == 5
                                ? "Disconnected"
                                : ""}
                    </>
                  ) : (
                    "Not connected"
                  )}
                </span>
              </p>
              <p>
                <label className="card-label">Account Balance:</label>
                <span>
                  {asCurrencyPrice(statData.balance, statData.currency)}
                </span>
              </p>
              <p>
                <label className="card-label">Membership Status:</label>
                <span
                  className={`recur-span ${myUser &&
                      myUser.subscription &&
                      myUser.subscription.status * 1 == 1
                      ? "active-span"
                      : "inactive-span"
                    }`}
                >
                  {myUser && myUser.subscription ? (
                    <>
                      {myUser.subscription.status * 1 == 1
                        ? "Active"
                        : myUser.subscription.status * 1 == 2 ||
                          myUser.subscription.status * 1 == 3
                          ? "Inactive"
                          : myUser.subscription.status * 1 == 4
                            ? "Cancelled"
                            : ""}
                    </>
                  ) : (
                    "None"
                  )}
                </span>
              </p>
              <p>
                <label className="card-label">Last Billing Date:</label>
                <span className="date-span">
                  {myUser && myUser.subscription ? (
                    <>{asDate(myUser.subscription.paid_at)}</>
                  ) : (
                    ""
                  )}
                </span>
              </p>
              <p>
                <label className="card-label">Next Billing Date:</label>
                <span className="date-span">
                  {myUser && myUser.subscription ? (
                    <>{asDate(myUser.subscription.next_date)}</>
                  ) : (
                    ""
                  )}
                </span>
              </p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} xxl={5}>
          <div className="app-card">
            <h2>Download App</h2>
            <div className="app-buttons">
              <div className="appstore-img">
                <img
                  src={theme == "light" ? GooglePlayWhImg : GooglePlayImg}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.akashx.protec",
                      "_blank"
                    );
                  }}
                />
              </div>
              <div className="divider" />
              <div className="appstore-img">
                <img
                  src={theme == "light" ? AppStoreWhImg : AppStoreImg}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/akashx/id1662500018",
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>

          </div>
        </Col>
        <Col xs={24} sm={24} xxl={12}>
          <div className="free3-card">
            <h2>Refer 3+ Friends, Get a Free Membership</h2>
            <div className="card-content">
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12}>
                  <div className="rank-progress-container">
                    <div className="progress-label-container">
                      <div className="label">Personally Enrolled</div>
                      <div className="value">({stats.pe_count}/3)</div>
                    </div>
                    <div className="rank-progress">
                      <Progress
                        percent={
                          100 * (stats.pe_count > 3 ? 1 : stats.pe_count / 3)
                        }
                        showInfo={false}
                        strokeColor="#c1954a"
                        strokeWidth={10}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={24} sm={12}>
                  <div className="rank-progress-container">
                    <div className="progress-label-container">
                      <div className="label">Personally Enrolled Volume</div>
                      <div className="value">
                        ({stats.total_order_amount}/{stats.qualified_amount})
                      </div>
                    </div>
                    <div className="rank-progress">
                      <Progress
                        percent={
                          100 *
                          (stats.total_order_amount > stats.qualified_amount
                            ? 1
                            : stats.total_order_amount / stats.qualified_amount)
                        }
                        showInfo={false}
                        strokeColor="#c1954a"
                        strokeWidth={10}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {/*               
              <div className="chart-wrap">
                {qualUsers.length < 3 ? (
                  <RingProgressChart
                    percent={qualUsers.length / 3}
                    statistic={{
                      content: {
                        formatter: () => `${qualUsers.length}/3`,
                        style: {
                          color: "#E29B00",
                          fontSize: "24px",
                          fontWeight: 700,
                        },
                      },
                    }}
                  />
                ) : (
                  <img src={CheckedImg} className="full-checked" />
                )}
                <p>{"Get 3 & Free"}</p>
              </div> */}
              <div className="f3r-wrap">
                {/* <div className="line-wrap">
                  {qualUsers.length > 0 ? (
                    <div className="line-item active">
                      <div className="user-photo">
                        <img src={qualUsers[0].image || (theme==='dark'?NoPhoto2Img:WhNoPhoto2Img)} />
                      </div>
                      <div className="name-span">
                        {qualUsers[0].first_name}&nbsp;
                        {qualUsers[0].last_name}
                      </div>
                      <div className="username-span">
                        {qualUsers[0].username}
                      </div>
                      <div className="country-span">
                        <CountryFlag country={qualUsers[0].country} />
                      </div>
                    </div>
                  ) : (
                    <div className="line-item">
                      <div className="user-photo">
                        <img src={(theme==='dark'?NoPhoto2Img:WhNoPhoto2Img)} />
                      </div>
                      <div className="name-span">&nbsp;</div>
                      <div className="username-span">&nbsp;</div>
                      <div className="country-span">&nbsp;</div>
                    </div>
                  )}
                  <div
                    className={`
                    line-space ${qualUsers.length > 1 ? "active" : ""}
                  `}
                  />
                  {qualUsers.length > 1 ? (
                    <div className="line-item active">
                      <div className="user-photo">
                        <img src={qualUsers[1].image || (theme==='dark'?NoPhoto2Img:WhNoPhoto2Img)} />
                      </div>
                      <div className="name-span">
                        {qualUsers[1].first_name}&nbsp;
                        {qualUsers[1].last_name}
                      </div>
                      <div className="username-span">
                        {qualUsers[1].username}
                      </div>
                      <div className="country-span">
                        <CountryFlag country={qualUsers[1].country} />
                      </div>
                    </div>
                  ) : (
                    <div className="line-item">
                      <div className="user-photo">
                        <img src={(theme==='dark'?NoPhoto2Img:WhNoPhoto2Img)} />
                      </div>
                      <div className="name-span">&nbsp;</div>
                      <div className="username-span">&nbsp;</div>
                      <div className="country-span">&nbsp;</div>
                    </div>
                  )}
                  <div
                    className={`
                    line-space ${qualUsers.length > 2 ? "active" : ""}
                  `}
                  />
                  {qualUsers.length > 2 ? (
                    <div className="line-item active">
                      <div className="user-photo">
                        <img src={qualUsers[2].image || (theme==='dark'?NoPhoto2Img:WhNoPhoto2Img)} />
                      </div>
                      <div className="name-span">
                        {qualUsers[2].first_name}&nbsp;
                        {qualUsers[2].last_name}
                      </div>
                      <div className="username-span">
                        {qualUsers[2].username}
                      </div>
                      <div className="country-span">
                        <CountryFlag country={qualUsers[2].country} />
                      </div>
                    </div>
                  ) : (
                    <div className="line-item">
                      <div className="user-photo">
                        <img src={(theme==='dark'?NoPhoto2Img:WhNoPhoto2Img)} />
                      </div>
                      <div className="name-span">&nbsp;</div>
                      <div className="username-span">&nbsp;</div>
                      <div className="country-span">&nbsp;</div>
                    </div>
                  )}
                  <div className="line-txt">{`=`}</div>
                  <div className="line-txt">{`Free Membership`}</div>
                </div> */}
                <div className="desc-wrap">
                  <p>
                    When you refer at least 3 friends to join Akashx and their
                    total order amount is over 3 times of your subscription
                    price, you'll receive a FREE membership! Refer 3+ friends to
                    Akashx who pay the $149 monthly membership and your next
                    billing date for Akashx is waived.
                  </p>
                </div>
                <div className="link-wrap">
                  <p>
                    <label>Your Website Link</label>
                    <br />
                    <span className="desc-link">
                      {`https://www.akashx.com/${myUser?.username}`}
                    </span>
                  </p>
                  <div>
                    <button onClick={handleCopy}>
                      <img src={LinkIcon} />
                      Website Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className="educator-root">
            <h2>Our Educators</h2>
            <div className="owl-carousel owl-theme owl-educator">
              {educators.map((el) => (
                <div className="item">
                  <div className="photo-span">
                    <img src={el.image || NoPhotoImg} />
                  </div>
                  <div className="name-span">
                    {`${el.first_name} ${el.last_name}`}
                  </div>
                  <div className="spec-span">{el.speciality}</div>
                  <div
                    className="desc-span"
                    dangerouslySetInnerHTML={{
                      __html: el.short_description,
                    }}
                  />
                  <div className="lang-wrap">
                    {el.languages.map((el2) => (
                      <span className="lang-span">{el2.title}</span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <DisclaimerBox />
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .stat-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 15px 25px;
    width: 100%;
    &.yellow-card {
      background: linear-gradient(108deg, #edd27b -16.88%, #a16a29 100.14%);
      border-color: transparent;
      border-width: 0;
    }
    .card-img {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50px;
      text-align: center;
      padding: 6px 0;
      svg {
        width: 36px;
        color: #ad7b36;
      }
    }
    .card-desc {
      margin: 4px 0 0;
      h4 {
        color: ${(props) => props.theme.palette.text};
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
      p {
        color: ${(props) => props.theme.palette.text};
        font-size: 24px;
        font-weight: 700;
        margin: 4px 0 0;
      }
    }
  }
  .owl-banner {
    .item {
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      // background: url(${Banner1Img}) no-repeat ${(props) =>
    props.theme.palette.cardBg};
      // background-size: auto;
      // background-size: cover;
      // width: 100%;
      // padding: 35px 40px 40px 65px;
      position: relative;
      overflow: hidden;
      .banner-img {
        width: 100%;
      }
      .mobile-banner-img {
        width: 100%;
        display: none;
      }
      @media (max-width: 768px) {
        .banner-img {
          display: none;
        }
        .mobile-banner-img {
          display: block;
        }
      }
      // h3 {
      //   color: ${(props) => props.theme.palette.text};
      //   font-size: 36px;
      //   font-weight: 600;
      //   margin: 0 0 6px 0;
      // }
      // p {
      //   color: ${(props) => props.theme.palette.text};
      //   font-size: 14px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      // }
      // ul {
      //   display: flex;
      //   width: 100%;
      //   margin: 15px 0;
      //   list-style: none;
      //   padding: 0;
      //   li {
      //     margin: 5px 5px 5px 0;
      //   }
      //   span {
      //     border-radius: 100px;
      //     background: rgba(193, 149, 74, 0.1);
      //     color: #c1954a;
      //     font-size: 12px;
      //     font-style: normal;
      //     font-weight: 300;
      //     padding: 6px 15px;
      //     text-transform: capitalize;
      //     display: inline-block;
      //   }
      // }
      // a {
      //   border-radius: 12px;
      //   color: #000;
      //   font-size: 16px;
      //   font-style: normal;
      //   font-weight: 500;
      //   padding: 10px 34px;
      //   text-transform: capitalize;
      //   display: inline-block;
      //   background: #c1954a;
      //   margin: 10px 0 0;
      // }
    }
    .owl-dots {
      position: absolute;
      left: 25px;
      top: calc(50% - 20px);
      display: flex;
      flex-direction: column;
      button {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        border: 1px solid #efedf9;
        margin: 4px 0;
        &.active {
          height: 22px;
          background: #c1954a;
          border-color: #c1954a;
        }
      }
    }
  }
  .mt5-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    .card-upper {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .photo-wrap {
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 4px solid #daa35a;
      }
    }
    .title-wrap {
      h4 {
        color: ${(props) => props.theme.palette.text};
        font-size: 18px;
        font-weight: 600;
        line-height: 1.1em;
        margin: 0;
      }
      h5 {
        color: ${(props) => props.theme.palette.secondaryText};
        font-size: 14px;
        font-weight: 400;
        line-height: 1.1em;
        margin: 4px 0;
      }
      p {
        color: ${(props) => props.theme.palette.text};
        font-size: 14px;
        font-weight: 400;
        line-height: 1.1em;
        margin: 0;
      }
    }
    .card-bottom {
      .card-label {
        padding-right: 10px;
      }
      p {
        margin: 15px 0;
        color: ${(props) => props.theme.palette.secondaryText};
        font-size: 16px;
        font-weight: 400;
        line-height: 1.1em;
        label {
          margin-right: 4px;
        }
        span {
          &.active-span {
            color: #54b498;
          }
          &.connected-span {
            color: #4784e0;
          }
          &.inactive-span {
            color: #f06b6b;
          }
          &.date-span {
            color: #daa35a;
          }
        }
      }
    }
  }
  .app-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      font-size: 18px;
      font-weight: 500;
      color: ${(props) => props.theme.palette.text};
    }
    .app-buttons {
      display:flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: center;
      @media (max-width: 1600px) {
        flex-direction: row;
        .divider {
          background: ${(props) => props.theme.palette.hr};
          width: 1px;
          height: 80px;
          margin: 0px 10px;
        }
      }
    }
    .appstore-img {
      margin: 8px 0;
      img {
        cursor: pointer;
        width: 100%;
      }
    }
    .divider {
      background: ${(props) => props.theme.palette.hr};
      width: 100%;
      height: 1px;
    }
  }
  .free3-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    h2 {
      font-size: 18px;
      font-weight: 500;
      color: ${(props) => props.theme.palette.text};
    }

    .card-content {
      margin-top: 20px;
    }

    .chart-wrap {
      background: ${(props) => props.theme.palette.chartBg};
      border: 1px solid ${(props) => props.theme.palette.chartBorder};
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 200px;
      height: 200px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .full-checked {
        width: 120px;
        height: 120px;
      }
      p {
        color: ${(props) => props.theme.palette.text};
        margin: 12px 0 0;
        font-size: 18px;
        font-weight: 600;
      }
    }
    // .f3r-wrap {
    //   width: calc(100% - 210px);
    //   @media (max-width: 768px) {
    //     width: 100%;
    //   }
    // }

    .line-wrap {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      .line-item {
        width: 150px;
        text-align: center;
        @media (max-width: 1600px) {
          width: 200px;
        }
        .user-photo {
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 2px solid #efedf910;
          }
        }
        .name-span {
          font-size: 17px;
          font-weight: 500;
          line-height: 19px;
          color: ${(props) => props.theme.palette.text};
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .username-span {
          font-size: 15px;
          font-weight: 400;
          line-height: 16px;
          color: ${(props) => props.theme.palette.secondaryText};
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .country-span {
          display: flex;
          justify-content: center;
          img {
            width: 24px !important;
            height: 24px !important;
            margin-right: 0px !important;
          }
        }
        &.active {
          .user-photo {
            img {
              border-color: #daa35a;
            }
          }
        }
        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin: 8px 0;
        }
      }
      .line-space {
        width: 70px;
        height: 2px;
        background: #efedf950;
        margin-top: 50px;
        &.active {
          background-color: #daa35a;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      .line-txt {
        color: ${(props) => props.theme.palette.text};
        margin-top: 40px;
        margin-left: 10px;
        font-size: 18px;
        line-height: 1.1em;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .desc-wrap {
      // padding-left: 30px;
      margin-top: 20px;
      p {
        color: ${(props) => props.theme.palette.text};
        font-size: 16px;
      }
      @media (max-width: 768px) {
        padding-left: 0;
        margin-top: 20px;
      }
    }
    .link-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin: 0 12px 0 0;
        font-size: 16px;
        label {
          color: ${(props) => props.theme.palette.secondaryText};
        }
        span {
          color: ${(props) => props.theme.palette.text};
        }
      }
      button {
        border-radius: 12px;
        background: #c1954a;
        width: 200px;
        padding: 8px 0;
        border: 0 none;
        color: #0d0d0d;
        font-size: 16px;
        img {
          margin-right: 8px;
        }
      }
      @media (max-width: 768px) {
        justify-content: space-between;
      }
    }
  }
  .educator-root {
    h2 {
      color: ${(props) => props.theme.palette.text};
      font-size: 24px;
      font-weight: 600;
      line-height: 1.1em;
      margin: 0 0 20px 0;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 20px;
      background: ${(props) => props.theme.palette.cardBg};
      border: 1px solid ${(props) => props.theme.palette.cardBorder};
      padding: 7px;
      height: 360px;
      @media (max-width: 768px) {
        margin-right: 10px;
      }
    }
    .photo-span {
      padding-top: 56.25%;
      width: 100%;
      position: relative;
      img {
        border-radius: 15px;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
      }
    }
    .name-span {
      color: ${(props) => props.theme.palette.text};
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
      margin-left: 12px;
      margin-right: 12px;
    }
    .spec-span {
      color: #daa35a;
      font-size: 12px;
      font-weight: 400;
      margin: -5px 12px 10px 12px;
    }
    .desc-span {
      font-size: 12px;
      line-height: 1.25em;
      color: ${(props) => props.theme.palette.secondaryText};
      margin: 0 12px;
      height: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .lang-wrap {
      display: flex;
      margin: 15px 12px 15px 12px;
      flex-wrap: wrap;
      .lang-span {
        background: #c1954a1a;
        padding: 6px 17px;
        color: #c1954a;
        font-size: 12px;
        line-height: 1.1em;
        border-radius: 18px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }

  .rank-progress-container {
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #c1954a;
      }
      .payment-tag {
        .active {
          background: rgba(0, 171, 85, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #36b37e;
        }
        .failed {
          background: rgba(255, 86, 48, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          border: none;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ff5630;
        }
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
`;
