import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { 
  Row, Col,
  Pagination, Spin, Modal,
  message,
  DisclaimerBox
} from 'components/common';
import { varIs } from 'common/var'
import { callGetApiWithAuth } from 'utils/api';
import { asDateTime } from 'utils/text';
import NoPhotoImg from 'assets/nophoto.svg';
import PlayBtnImg from 'assets/started-play.png';
import WhPlayBtnImg from 'assets/started-play_wh.png';
import { ReactComponent as CalendarIcon } from 'assets/menu-calendar.svg';

export default function StreamPage() {
  const { id } = useParams()
  const history = useHistory()
  const myUser = useSelector(state => state.auth.user)
  const theme = useSelector(state => state.ui.theme)
  const [detailData, setDetailData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [eventUrl, setEventUrl] = useState('')
  const [chatUrl, setChatUrl] = useState('')
  const [isChatOpened, setIsChatOpened] = useState(false)

  const onGetDetailData = ({ data }) => {
    setDetailData(data)
    setIsLoading(false)
  }
  const onFailDetailData = () => {
    setIsLoading(false)
  }

  const goStream = (did) => {
    history.push(`/stream/${did}`)
  }

  useEffect(() => {
    setIsLoading(true)
    setDetailData(undefined)
    // const params = new URLSearchParams(window.location.search);
    // if (params.has('stream_id')) {
      callGetApiWithAuth(`akashx/stream/${id}/detail`, 
        onGetDetailData, onFailDetailData
      )
    // }
  }, [id])

  useEffect(() => {
    if (detailData) {
      // Validation first
      let chatUrl0 = '';
      let eventUrl0 = '';
      if (varIs('akaStream.videoType', detailData.video_type, 'vimeo')) {
        eventUrl0 = detailData.vimeo_url
        if (detailData.vimeo_url && 
          detailData.vimeo_url.indexOf('https://vimeo.com/event/')>=0
        ) {
          let pos = detailData.vimeo_url.lastIndexOf('/')
          let preUrl = detailData.vimeo_url.substring(0, pos)
          let postUrl = detailData.vimeo_url.substring(pos)
          eventUrl0 = preUrl+'/embed'+postUrl
          if (!varIs('akaStream.liveStatus', detailData.live_status, 'recorded')) {
            chatUrl0 = preUrl+'/chat'+postUrl
          }
        } else {
          eventUrl0 = detailData.vimeo_url
          .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
        }
      } else {
        eventUrl0 = `${process.env.REACT_APP_ZOOM_URL}/aka_zoom?stream_id=${detailData.id}&username=${myUser.username}&email=${myUser.email}`
      }

      if (!eventUrl0) {
        message.error('Session URL is incorrect')
        return
      }

      setEventUrl(eventUrl0)
      setChatUrl(chatUrl0)
    }
  }, [detailData])

  return (
    <Wrapper>
      <div className='tot-root'>
        {detailData ?  
        <>
          <div 
            className={`str-root`}
          >
            <div className={`str-wrap  ${
              (varIs('akaStream.liveStatus', detailData.live_status, 'pending') &&  
                varIs('akaStream.videoType', detailData.video_type, 'vimeo')
              ) ? '': 'full-width'
            }`}>
              <iframe src={eventUrl} 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                width="100%" height="100%" 
                title={'Vimeo Session'}
              />
            </div>
            {chatUrl && 
            <div className='chat-wrap'>
              <iframe src={chatUrl} 
                title={'Vimeo Session Chat'}
                width="100%" height="100%" frameborder="0"
              />
            </div>}
          </div>
          <div className='edu-root'>
            <h2>{detailData.title}</h2>
            {detailData.educator && 
            <div className='edu-card'>
              <div className='photo-wrap'>
                <img src={detailData.educator.image} />
              </div>
              <div className='edur-wrap'>
                <h4>
                  {detailData.educator.first_name}&nbsp;
                  {detailData.educator.last_name}
                </h4>
                <p className='spec-span'>
                  {detailData.educator.speciality}
                </p>
                <p className='desc-span'
                  dangerouslySetInnerHTML={{
                    __html: detailData.educator.description
                  }}
                />
              </div>
            </div>}
          </div>
          <div className='btm-root'>
            <ReplaySection detailData={detailData} 
              goStream={goStream}
            />
            <div className='sche-root'>
              <h2>Live Schedule</h2>
              <div className='sche-card'>
                {detailData.scheduleds.map(el => (
                  <div className='sche-wrap'>
                    <div className='schl-wrap'>
                      <div className='photo-wrap'>
                        <img src={el.educator?.image || NoPhotoImg} />
                      </div>
                      <div className='name-wrap'>
                        <p className='name-span'>
                          {el.educator?.first_name}&nbsp;
                          {el.educator?.last_name}
                        </p>
                        <p className='date-span'>
                          {moment(el.start_at).format('dddd, HA')}&nbsp;
                          {el.timezone_abbr}
                        </p>
                      </div>
                    </div>
                    <div className='schr-wrap'>
                      <img src={theme === 'dark'?PlayBtnImg:WhPlayBtnImg} 
                        onClick={() => goStream(el.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
        : <div style={{ minHeight: 400 }} />}
        {isLoading && 
        <div className='spin-wrap'>
          <Spin spinning={true} />
        </div>}
      </div>
      <div style={{ height: 15 }} />
      <DisclaimerBox />
    </Wrapper>
  )
}

const ReplaySection = ({ detailData, goStream }) => {
  const [listData, setListData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 6,
    total: 0
  })
  const [isLoading, setIsLoading] = useState(false)

  const onGetListData = ({ data }) => {
    setListData(data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    })
    setIsLoading(false)
  }
  const onFailListData = () => {
    setIsLoading(false)
  }
  const loadListData = (paginationParam0) => {
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    setIsLoading(true)
    callGetApiWithAuth(
      `akashx/stream/${detailData.educator_id}/recordeds?${q}`,
      onGetListData, onFailListData
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadListData(paginationParam0)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadListData(paginationParam0)
  }

  useEffect(() => {
    if (detailData) {
      handleSearch()
    }
  }, [detailData])

  return (
    <div className='rep-root'>
      <h2>Session Replay</h2>
      <Row className='rep-wrap' gutter={[30, 30]}>
        {listData.map(el => (
        <Col xs={24} sm={12} xxl={8}>
          <ReplayCard data={el} />
        </Col>
        ))}
      </Row>
      <div className='pagination-wrap'>
        <Pagination
          current={paginationParam.currentPage}
          pageSize={paginationParam.perPage}
          total={paginationParam.total}
          showSizeChanger={false}
          onChange={onPageChange}
          hideOnSinglePage={true}
        />
      </div>
    </div>
  )
}

const ReplayCard = ({ data }) => {
  const myUser = useSelector(state => state.auth.user)
  const theme = useSelector(state => state.ui.theme)
  const [modalMode, setModalMode] = useState(undefined)
  const [eventUrl, setEventUrl] = useState('')

  useEffect(() => {
    if (data) {
      // Validation first
      let chatUrl0 = '';
      let eventUrl0 = '';
      if (varIs('akaStream.videoType', data.video_type, 'vimeo')) {
        eventUrl0 = data.vimeo_url
        if (data.vimeo_url && 
          data.vimeo_url.indexOf('https://vimeo.com/event/')>=0
        ) {
          let pos = data.vimeo_url.lastIndexOf('/')
          let preUrl = data.vimeo_url.substring(0, pos)
          let postUrl = data.vimeo_url.substring(pos)
          eventUrl0 = preUrl+'/embed'+postUrl
          if (!varIs('akaStream.liveStatus', data.live_status, 'recorded')) {
            chatUrl0 = preUrl+'/chat'+postUrl
          }
        } else {
          eventUrl0 = data.vimeo_url
          .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
        }
      } else {
        eventUrl0 = `${process.env.REACT_APP_ZOOM_URL}/aka_zoom?stream_id=${data.id}&username=${myUser.username}&email=${myUser.email}`
      }

      if (!eventUrl0) {
        message.error('Session URL is incorrect')
        return
      }

      setEventUrl(eventUrl0)
    }
  }, [data])

  return (
    <div className='rep-card'>
      <div className='card-img'>
        <img src={data.thumbnail || NoPhotoImg} />
      </div>
      <div className='card-btm'>
        <div>
          <h4>{data.title}</h4>
          <p className='date-span'>
            <CalendarIcon />
            &nbsp;&nbsp;&nbsp;
            {moment(data.start_at).format('dddd, HA')}&nbsp;
            {data.timezone_abbr}
          </p>
        </div>
        <div className='btn-wrap'>
          <img src={theme==='dark'?PlayBtnImg:WhPlayBtnImg} 
            onClick={() => setModalMode('video')}
          />
        </div>
        {modalMode === 'video' && 
        <Modal width={720} closable={false}
          onCancel={() => setModalMode(undefined)}
        >
          <RepWrapper>
            <iframe src={eventUrl} 
              frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen 
              width="100%" height="100%" 
              title={'Vimeo Session'}
            />
          </RepWrapper>
        </Modal>
        }
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  .tot-root {
    position: relative;
  }
  .spin-wrap {
    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .str-root {
    display: flex;
    justify-content: space-between;
  }
  .str-wrap {
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    border-radius: 10px;
    width: calc(100% - 390px);
    &.full-width {
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    iframe {
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
    }
  }
  .chat-wrap {
    width: 372px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .edu-root {
    margin: 60px 0;
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
      color: ${props=>props.theme.palette.text};
      margin-bottom: 20px;
    }
    .edu-card {
      border: 1px solid ${props=>props.theme.palette.cardBorder};
      background: ${props=>props.theme.palette.cardBg};
      padding: 20px;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .photo-wrap {
        width: 260px;
        img {
          width: 100%;
          height: 240px;
          object-fit: cover;
          border-radius: 15px;
        }
      }
      .edur-wrap {
        width: calc(100% - 290px);
      }
      h4 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.2em;
        color: ${props=>props.theme.palette.text};
        margin: 0;
      }
      .spec-span {
        color: #DAA35A;
        font-size: 18px;
      }
      .desc-span {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.5em;
        color: ${props=>props.theme.palette.secondaryText};
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 108px;
        margin: 0;
        p {
          margin: 0;
        }
      }
      @media (max-width: 768px) {
        .photo-wrap {
          width: 100%;
          img {
            height: 180px;
          }
        }
        .edur-wrap {
          width: 100%;
        }
        h4 {
          margin-top: 12px;
        }
      }
    }
  }
  .pagination-wrap {
    margin: 12px 0;
    display: flex;
    justify-content: center;
    .ant-pagination-prev .ant-pagination-item-link, 
    .ant-pagination-next .ant-pagination-item-link {
      background: transparent;
      border-color: transparent;
      color: ${props=>props.theme.palette.text};
    }
    .ant-pagination-disabled .ant-pagination-item-link, 
    .ant-pagination-disabled:hover .ant-pagination-item-link, 
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
      color: rgba(255, 255, 255, 0.5);
    }
    .ant-pagination-item {
      background: transparent;
      border-color: transparent;
      color: ${props=>props.theme.palette.secondaryText};
    }
    .ant-pagination-item-active {
      color: #DAA35A;
    }
  }
  .btm-root {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .rep-root {
    width: calc(100% - 460px);
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.2em;
      color: ${props=>props.theme.palette.text};
      margin: 0 0 18px 0;
    }
    .rep-card {
      border: 1px solid ${props=>props.theme.palette.cardBorder};
      background: ${props=>props.theme.palette.cardBg};;
      padding: 20px;
      border-radius: 20px;
      .card-img {
        img {
          width: 100%;
          height: 210px;
          object-fit: cover;
          border-radius: 15px;
        }
      }
      .card-btm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 10px 0;
      }
      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 2.5em;
        color: ${props=>props.theme.palette.text};
        margin: 0;
      }
      .date-span {
        color: #DAA35A;
        font-size: 14px;
        line-height: 2em;
        margin: 0;
        display: flex;
        align-items: center;
      }
      .btn-wrap {
        img {
          width: 70px;
          height: 70px;
          cursor: pointer;
        }
      }
    }
    @media (max-width: 1366px) {
      width: 100%;
    }
  }
  .sche-root {
    width: 435px;
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.2em;
      color: ${props=>props.theme.palette.text};
      margin: 0 0 18px 0;
    }
    .sche-card {
      border: 1px solid ${props=>props.theme.palette.cardBorder};
      background: ${props=>props.theme.palette.cardBg};
      padding: 15px 30px;
      border-radius: 20px;
    }
    .sche-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${props=>props.theme.palette.cardBorder};
      padding: 14px 0;
      &:last-child {
        border-color: transparent;
      }
    }
    .schl-wrap {
      display: flex;
      align-items: center;
    }
    .photo-wrap {
      margin-right: 12px;
      img {
        width: 38px;
        height: 38px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name-wrap {
      .name-span {
        font-size: 16px;
        color: ${props=>props.theme.palette.text};
        line-height: 1.2em;
        margin: 4px 0;
      }
      .date-span {
        font-size: 14px;
        color: #E29B00;
        line-height: 1.2em;
        margin: 4px 0;
      }
    }
    .schr-wrap {
      img {
        width: 52px;
        height: 52px;
        cursor: pointer;
      }
    }
    @media (max-width: 1366px) {
      width: 100%;
    }
  }
`
const RepWrapper = styled.div`
  height: 480px;
  border: 1px solid ${props=>props.theme.palette.cardBorder};
  background: ${props=>props.theme.palette.cardBg};
  margin: -24px;
`
