import React from "react";
import { 
  BrowserRouter, Route, Switch, Redirect 
} from "react-router-dom";

import AuthedLayout from "layouts/authed/AuthedLayout";
import AuthLayout from "layouts/auth/AuthLayout";

import LogoutRoute from "views/auth/logout/LogoutRoute";
import LoginPage from "views/auth/login/LoginPage";
import HomePage from "views/home/HomePage";
import StartedPage from "views/started/StartedPage";
import AcademyPage from "views/academy/AcademyPage";
import BeginnerPage from "views/academy/BeginnerPage";
import IntermediatePage from "views/academy/IntermediatePage";
import AdvancedPage from "views/academy/AdvancedPage";
import ExpertPage from "views/academy/ExpertPage";
import CryptoPage from "views/academy/CryptoPage";
import EducatorPage from "views/academy/EducatorPage";
import ThreeFreePage from "views/threeFree/ThreeFreePage";
import SchedulePage from "views/stream/SchedulePage";
import StreamPage from "views/stream/StreamPage";
import ReplayPage from "views/replay/ReplayPage";
import ReplayEducatorPage from "views/replay/ReplayEducatorPage";
import VideoEmbed from "views/stream/VideoEmbed";
import ForexAlertPage from "views/alert/ForexAlertPage";
import CryptoAlertPage from "views/alert/CryptoAlertPage";
import BinaryOptionAlertPage from "views/alert/BinaryOptionAlertPage";
import CommodityAlertPage from "views/alert/CommodityAlertPage";
import IndexAlertPage from "views/alert/IndexAlertPage";
import AllAlertPage from "views/alert/AllAlertPage";
import TapPage from "views/tap/TapPage";
import PulsePage from "views/scanner/PulsePage";
import RhythmPage from "views/scanner/RhythmPage";
import GoldenRainPage from 'views/scanner/GoldenRainPage';
import CalendarPage from "views/calendar/CalendarPage";
import CalculatorPage from "views/calculator/CalculatorPage";
import ResourcePage from "views/resource/ResourcePage";
import NewsPage from 'views/news/NewsPage';
import TraderLbdPage from 'views/leaderboard/TraderLbdPage';
import StrategyLbdPage from 'views/leaderboard/StrategyLbdPage';
import FollowerLbdPage from 'views/leaderboard/FollowerLbdPage';
import TrendCapturePage from 'views/scanner/TrendCapturePage';
import VolatilitySurgePage from 'views/scanner/VolatilitySurgePage';
import BreakoutMomentumPage from 'views/scanner/BreakoutMomentumPage';
import PivotPointReversalPage from 'views/scanner/PivotPointReversalPage';
import DynamicEquilibriumPage from 'views/scanner/DynamicEquilibriumPage';
import CurrencyStrengthPage from 'views/tool/CurrencyStrengthPage';
import GlossaryPage from 'views/tool/GlossaryPage';
import TrendAnalysisPage from 'views/tool/TrendAnalysisPage';
import FinancialNewsPage from 'views/tool/FinancialNewsPage';


const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute path="/login" component={LoginPage} />
        <Route path="/logout" component={LogoutRoute} />
        <AuthedRoute path="/" 
          component={HomePage} 
          fullLayout exact={true} 
        />
        <AuthedRoute path="/started" 
          component={StartedPage} 
          fullLayout
        />
        <AuthedRoute path="/academy" 
          component={AcademyPage} 
          fullLayout exact={true} 
        />
        <AuthedRoute path="/academy/beginner" 
          component={BeginnerPage} 
          fullLayout 
        />
        <AuthedRoute path="/academy/intermediate" 
          component={IntermediatePage} 
          fullLayout 
        />
        <AuthedRoute path="/academy/advanced" 
          component={AdvancedPage} 
          fullLayout 
        />
        <AuthedRoute path="/academy/expert" 
          component={ExpertPage} 
          fullLayout 
        />
        <AuthedRoute path="/academy/crypto" 
          component={CryptoPage} 
          fullLayout 
        />
        <AuthedRoute path="/academy/educator" 
          component={EducatorPage} 
          fullLayout 
        />
        <AuthedRoute path="/three_free" 
          component={ThreeFreePage} 
          fullLayout
        />
        <AuthedRoute path="/schedule" 
          component={SchedulePage} 
          fullLayout
        />
        <AuthedRoute path="/stream/:id"
          component={StreamPage}
          fullLayout
          exact={true}
        />
        <AuthedRoute path="/replays" 
          component={ReplayEducatorPage} 
          fullLayout
        />
        <AuthedRoute path="/replay/:id" 
          component={ReplayPage} 
          fullLayout
          exact={true}
        />
        <Route path="/video_embed/:id" 
          component={VideoEmbed} 
        />
        {/* <AuthedRoute path="/alert/forex" 
          component={ForexAlertPage} 
          fullLayout
        />
        <AuthedRoute path="/alert/crypto" 
          component={CryptoAlertPage} 
          fullLayout
        />
        <AuthedRoute path="/alert/binary_option" 
          component={BinaryOptionAlertPage} 
          fullLayout
        />
        <AuthedRoute path="/alert/commodity" 
          component={CommodityAlertPage} 
          fullLayout
        />
        <AuthedRoute path="/alert/index" 
          component={IndexAlertPage} 
          fullLayout
        /> */}
        <AuthedRoute path="/alert" 
          component={AllAlertPage} 
          fullLayout
        />
        <AuthedRoute path="/tap" 
          component={TapPage} 
          fullLayout
        />
        <AuthedRoute path="/tap-traders/trader" 
          component={TraderLbdPage} 
          fullLayout
        />
        <AuthedRoute path="/leaderboard/strategy" 
          component={StrategyLbdPage} 
          fullLayout
        />
        <AuthedRoute path="/leaderboard/follower" 
          component={FollowerLbdPage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/pulse" 
          component={PulsePage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/rhythm" 
          component={RhythmPage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/golden_rain" 
          component={GoldenRainPage} 
          fullLayout
        />
        <AuthedRoute path="/calendar" 
          component={CalendarPage} 
          fullLayout
        />
        <AuthedRoute path="/calculator" 
          component={CalculatorPage} 
          fullLayout
        />
        <AuthedRoute path="/resource" 
          component={ResourcePage} 
          fullLayout
        />
        <AuthedRoute path="/news" 
          component={NewsPage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/elite_sniper" 
          component={TrendCapturePage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/volatility_surge" 
          component={VolatilitySurgePage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/breakout_momentum" 
          component={BreakoutMomentumPage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/pivot_point_reversal" 
          component={PivotPointReversalPage} 
          fullLayout
        />
        <AuthedRoute path="/scanner/dynamic_equilibrium" 
          component={DynamicEquilibriumPage} 
          fullLayout
        />
        <AuthedRoute path="/tool/currency_strength" 
          component={CurrencyStrengthPage} 
          fullLayout
        />
        <AuthedRoute path="/tool/trend_analysis" 
          component={TrendAnalysisPage} 
          fullLayout
        />
        <AuthedRoute path="/tool/glossary" 
          component={GlossaryPage} 
          fullLayout
        />
        <AuthedRoute path="/tool/financial_news" 
          component={FinancialNewsPage} 
          fullLayout
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

const AuthRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <AuthLayout pathname={props.location.pathname}>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

const AuthedRoute = ({
  component: Component,
  fullLayout,
  publicRoute,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <AuthedLayout
          fullLayout={fullLayout}
          pathname={props.location.pathname}
          publicRoute={publicRoute}
        >
          <Component {...props} />
        </AuthedLayout>
      )}
    />
  );
};
