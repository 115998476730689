import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import SignatureCanvas from "react-signature-canvas";
import { varIs, varIsIn, varKey } from "common/var";
import {
  Row, Col,
  VideoModal,
  FormItem, Input, Select2, Radio,
  Popconfirm, Button,
  SuccessNotification,
  DisclaimerBox,
} from "components/common";
import {
  callGetApiWithAuth,
  callPostApiWithAuth,
  callPutApiWithAuth,
} from "utils/api";
import { BsSquare, BsCheck2Square } from "react-icons/bs";
import ThumbImg from "assets/started-thumb.jpg";
import PlayImg from "assets/started-play.png";
import NoImage from "assets/no_image.png";

export default function StartedPage() {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [staVideos, setStaVideos] = useState([])
  const [modalMode, setModalMode] = useState(undefined)

  const onGetVideos = ({ data }) => {
    setStaVideos(data)
  }

  useEffect(() => {
    dispatch({
      type: "auth.RELOAD",
    });
    callGetApiWithAuth(`akashx/started/started_videos`, onGetVideos)
  }, []);

  return (
    <Wrapper>
      <Row gutter={[15, 15]}>
        <Col xs={24} sm={10}>
          <div className="tour-card">
            <div className="video-wrap">
              <img src={ThumbImg} className="thumb" />
              <img src={PlayImg} className="play-btn" 
                onClick={()=>setModalMode('video')}
              />
            </div>
            {modalMode == 'video' && 
              <VideoModal
                onCancel={() => setModalMode(undefined)}
                src={'https://player.vimeo.com/video/971296119'}
                width={1200}
                isVimeo
              />
            }
          </div>
          <div style={{ height: 15 }} />
          <div className="welcome-card">
            <h2>Welcome to Tap To Trade</h2>
            <p>
              Tap To Trade is an exciting feature of the Akashx platform where
              you can accept trades with a couple of clicks from your favorite
              Educators and Traders to your connected trading account.
            </p>
            <p><strong>Here’s how it works:</strong></p>
            <ul>
              <li>
                <strong>Connect your trading account.</strong> 
                &nbsp;You can use a live or demo account. 
                We suggest using a demo account prior to ever connecting a live account.
              </li>
              <li>
                <strong>Trades will appear in the Tap To Trade section of Akashx and
                you will have the ability to Approve them.</strong> Once the trade has been
                approved, it has been executed onto your trading account. You
                are 100% in full control and can close the trade at anytime.
              </li>
              <li>
                <strong>All of the trades that are called from the Akashx platform will have an Entry Price, Take Profit, and Stop Loss with a countdown. </strong>After the countdown has expired, the trade cannot be approved. 
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={14}>
          <div className="form-card">
            {myUser && myUser.algo_setting ? <AccountForm /> : <SignForm />}
          </div>
        </Col>
        <Col xs={24}>
          <div className='tour-cont'>
            <Row gutter={[20, 20]}>
              {staVideos.map((el) => (
                <Col xs={24} sm={12} lg={12} xl={12} xxl={8}>
                  <StaVideoCard data={el} />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
        <Col xs={24}>
          <DisclaimerBox />
        </Col>
      </Row>
    </Wrapper>
  );
}

const SignForm = () => {
  const dispatch = useDispatch();
  const [isAgreed1, setIsAgreed1] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    signImage: null,
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const canvasRef = useRef(null);

  const useWindowDimensions = () => {
    const hasWindow = typeof window !== "undefined";

    function getWindowDimensions() {
      const height = hasWindow ? window.innerHeight : null;
      const width_ = hasWindow ? window.innerWidth : null;
      return {
        height,
        width_,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  };

  const { height, width_ } = useWindowDimensions();

  const onFormInput = (f, v) => {
    let formData0 = {
      ...formData,
      [f]: v,
    };
    setFormData(formData0);
  };

  const onSubmitDone = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    dispatch({
      type: "auth.RELOAD",
    });
  };
  const onSubmitFail = () => {
    setIsUpdating(false);
  };
  const handleSubmit = () => {
    let errorMessages0 = [];
    if (!isAgreed1) {
      errorMessages0.push({
        field: "agreement",
        message: "Please check all agreements",
      });
    }
    if (!formData.full_name) {
      errorMessages0.push({
        field: "full_name",
        message: "Required",
      });
    }
    setErrorMessages(errorMessages0);
    if (errorMessages0.length > 0) return;

    const body = {
      signImage: canvasRef.current.toDataURL(),
      full_name: formData.full_name,
    };
    setIsUpdating(true);
    callPostApiWithAuth(
      `akashx/started/upload_sign_image`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };

  return (
    <div className="sign-form">
      <h2>Legal Disclaimers</h2>
      <FormItem
        errorMessages={errorMessages.filter((el) => el.field === "agreement")}
      >
        <div className="agree-wrap" onClick={() => setIsAgreed1(!isAgreed1)}>
          <span>{isAgreed1 ? <BsCheck2Square /> : <BsSquare />}</span>
          <span>
            I have read and understand the following disclaimer: <br/>
            Trading foreign exchange on margin carries a high level of risk 
            and may not be suitable for all investors. Past performance is 
            not indicative of future results. The high degree of leverage 
            can work against you as well as for you. Before deciding to 
            trade foreign exchange, you should carefully consider your 
            investment objectives, level of experience, and risk appetite.
            {' '}<br/>
            The possibility exists that you could sustain a loss of some or 
            all of your initial investment and, therefore, you should not 
            invest money that you cannot afford to lose. You should be aware 
            of all the risks associated with foreign exchange trading and seek 
            advice from an independent financial advisor if you have any doubts.
            {' '}<br/>
            Any opinions, news, research, analyses, prices, or other information 
            contained in this communication or on our platforms are provided 
            as general market commentary and do not constitute investment 
            advice.
            {' '}<br/>
            The Company will not accept liability for any loss or damage, 
            including but not limited to, any loss of profit, which may 
            arise directly or indirectly from the use of or reliance 
            on such information.
            {' '}<br/>
            We do not guarantee the accuracy or completeness of any 
            information, analysis, or commentary provided. All 
            information is subject to change without notice.
          </span>
        </div>
      </FormItem>
      <div className="form-wrap">
        <FormItem
          label={"Full Name"}
          errorMessages={errorMessages.filter((el) => el.field === "full_name")}
        >
          <div style={{ width: 590, maxWidth: "100%" }}>
            <Input
              value={formData.full_name}
              onChange={(e) => onFormInput("full_name", e.target.value)}
            />
          </div>
        </FormItem>
      </div>
      <div className="sign-wrap">
        <FormItem
          errorMessages={errorMessages.filter((el) => el.field === "signImage")}
        >
          <div className="sign-head">
            <label>Write your signature</label>
            <button
              className={"canvas-clear-btn"}
              onClick={() => canvasRef.current.clear()}
            >
              Clear
            </button>
          </div>
          {width_ > 768 ? (
            <SignatureCanvas
              ref={canvasRef}
              canvasProps={{
                width: 590,
                height: 100,
                className: "sigCanvas",
              }}
            />
          ) : (
            <SignatureCanvas
              ref={canvasRef}
              canvasProps={{
                width: 290,
                height: 50,
                className: "sigCanvas",
              }}
            />
          )}
        </FormItem>
      </div>
      <div className="action-group">
        <Popconfirm onConfirm={handleSubmit}>
          <Button loading={isUpdating}>Submit</Button>
        </Popconfirm>
      </div>
    </div>
  );
};

const AccountForm = () => {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    broker: "",
    server: "",
    mt_version: 'mt_5',
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [servers, setServers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const onGetBrokers = ({ data }) => {
    setBrokers(data.brokers);
    setServers(data.servers);
  };
  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onSubmitDone = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    dispatch({
      type: "auth.RELOAD",
    });
  };
  const onSubmitFail = () => {
    setIsUpdating(false);
    dispatch({
      type: "auth.RELOAD",
    });
  };
  const handleRequest = () => {
    let errorMessages0 = [];
    [
      "username", "password", "passwordConfirm", 
      "server", "broker"
    ].forEach((k) => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: "Required",
        });
      }
    });
    if (
      formData.password &&
      formData.passwordConfirm &&
      formData.password != formData.passwordConfirm
    ) {
      errorMessages0.push({
        field: "passwordConfirm",
        message: "Password confirm is incorrect",
      });
    }
    setErrorMessages(errorMessages0);
    if (errorMessages0.length > 0) return;

    const body = { ...formData };
    setIsUpdating(true);
    callPutApiWithAuth(
      `akashx/started/request_connect`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };
  const handleReset = () => {
    let errorMessages0 = [];
    setErrorMessages(errorMessages0);
    if (errorMessages0.length > 0) return;

    const body = {};
    setIsUpdating(true);
    callPutApiWithAuth(
      `akashx/started/reset`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };

  useEffect(() => {
    callGetApiWithAuth(`akashx/started/brokers`, onGetBrokers);
  }, []);

  useEffect(() => {
    if (myUser && myUser.algo_setting) {
      if (
        varIsIn("userAlgoSetting.status", myUser.algo_setting.status, [
          "connected",
          "disconnected",
          "copier_pending",
          "copier_failed",
        ])
      ) {
        setIsDisabled(true);
      } else if (
        varIsIn("userAlgoSetting.status", myUser.algo_setting.status, [
          "pending",
        ])
      ) {
        setIsDisabled(false);
      }
      setFormData({
        ...formData,
        ...myUser.algo_setting,
        password: "",
        passwordConfirm: "",
      });
    }
  }, [myUser]);

  return (
    <div className="account-form">
      <h2><strong>Connect Your Account</strong></h2>
      <p>
        Tap To Trade is compatible with MetaTrader 4, 5, and TradeLocker. 
        Simply connect your account and you will be able to approve trade 
        alerts from the app.
      </p>
      <div className="algo-wrap">
        <h4>Status</h4>
        <div
          className={`status-badge ${varKey(
            "userAlgoSetting.status",
            myUser?.algo_setting?.status
          )}`}
        >
          {varIs(
            "userAlgoSetting.status",
            myUser?.algo_setting?.status,
            "pending"
          )
            ? "Pending"
            : varIs(
                "userAlgoSetting.status",
                myUser?.algo_setting?.status,
                "copier_pending"
              )
            ? "Connecting"
            : varIs(
                "userAlgoSetting.status",
                myUser?.algo_setting?.status,
                "copier_failed"
              )
            ? "Failed"
            : varIs(
                "userAlgoSetting.status",
                myUser?.algo_setting?.status,
                "disconnected"
              )
            ? "Disconnected"
            : varIs(
                "userAlgoSetting.status",
                myUser?.algo_setting?.status,
                "connected"
              )
            ? "Connected"
            : varIs(
                "userAlgoSetting.status",
                myUser?.algo_setting?.status,
                "connect_failed"
              )
            ? "Failed"
            : ""}
        </div>
        {myUser &&
          myUser.algo_setting &&
          myUser.algo_setting.failed_message &&
          varIsIn("userAlgoSetting.status", myUser.algo_setting.status, [
            "connect_failed",
            "copier_failed",
          ]) && (
            <div className="fail-msg">
              {myUser?.algo_setting?.failed_message}
            </div>
          )}
      </div>
      <Row gutter={[15, 15]}>
        <Col xs={24}>
          <Radio.Group value={formData.mt_version}
            onChange={e=>{
              setFormData({
                ...formData,
                mt_version: e.target.value,
                broker: '',
                server: '',
              })
            }}
            disabled={isDisabled}
          >
            <Radio value={'mt_4'}>MetaTrader 4</Radio>
            <Radio value={'mt_5'}>MetaTrader 5</Radio>
            <Radio value={'trade_locker'}>Trade Locker</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24}>
          {formData.mt_version=='trade_locker'?
          <FormItem
            label={"Account Email"}
            errorMessages={errorMessages.filter(
              (el) => el.field === "username"
            )}
          >
            <Input
              value={formData.username}
              onChange={(e) => onFormInput("username", e.target.value)}
              disabled={isDisabled}
              type={'email'}
            />
          </FormItem>
          : 
          <FormItem
            label={"Account Number"}
            errorMessages={errorMessages.filter(
              (el) => el.field === "username"
            )}
          >
            <Input
              value={formData.username}
              onChange={(e) => onFormInput("username", e.target.value)}
              disabled={isDisabled}
            />
          </FormItem>}
        </Col>
        <Col xs={24} sm={24}>
          <FormItem
            label={"Account Password"}
            errorMessages={errorMessages.filter(
              (el) => el.field === "password"
            )}
          >
            <Input
              value={formData.password}
              type={"password"}
              onChange={(e) => onFormInput("password", e.target.value)}
              disabled={isDisabled}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24}>
          <FormItem
            label={"Password Confirm"}
            errorMessages={errorMessages.filter(
              (el) => el.field === "passwordConfirm"
            )}
          >
            <Input
              value={formData.passwordConfirm}
              type={"password"}
              onChange={(e) => onFormInput("passwordConfirm", e.target.value)}
              disabled={isDisabled}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24}>
          <FormItem
            label={"Broker Name"}
            errorMessages={errorMessages.filter((el) => el.field === "broker")}
          >
            <Select2
              value={formData.broker}
              onChange={(e) => onFormInput("broker", e.target.value)}
              disabled={isDisabled}
              options={[
                { label: 'Select Broker', value: '' },
                ...brokers.filter(el => {
                  let hasOpts = false;
                  servers.forEach(el2 => {
                    if (el2.broker_id==el.broker_id && 
                      el2.mt_version === formData.mt_version
                    ) {
                      hasOpts = true;
                    }
                  })
                  return hasOpts;
                })
                .map((el) => ({
                  label: el.name, value: el.broker_id
                }))
              ]}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24}>
          <FormItem
            label={"Broker Server"}
            errorMessages={errorMessages.filter((el) => el.field === "server")}
          >
            <Select2
              value={formData.server}
              onChange={(e) => onFormInput("server", e.target.value)}
              disabled={isDisabled}
              options={[
                { label: 'Select Server', value: '' }, 
                ...servers.filter((el) => 
                  el.broker_id === formData.broker && 
                  el.mt_version === formData.mt_version
                )
                .map((el) => ({
                  label: el.name, value: el.server_id,
                }))
              ]}
            />
          </FormItem>
        </Col>
      </Row>
      <div className="action-group">
        {myUser &&
          myUser.algo_setting &&
          varIsIn("userAlgoSetting.status", myUser.algo_setting.status, [
            "pending",
          ]) && (
            <Popconfirm onConfirm={handleRequest}>
              <Button loading={isUpdating} disabled={isDisabled}>
                Connect
              </Button>
            </Popconfirm>
          )}
        {myUser &&
          myUser.algo_setting &&
          varIsIn("userAlgoSetting.status", myUser.algo_setting.status, [
            "connected",
            "copier_pending",
            "copier_failed",
          ]) && (
            <>
              <div style={{ width: 6 }} />
              <Popconfirm onConfirm={handleReset}>
                <Button loading={isUpdating}>Reset Account</Button>
              </Popconfirm>
            </>
          )}
      </div>
    </div>
  );
};

const StaVideoCard = ({ data }) => {
  const [modalMode, setModalMode] = useState(undefined);

  const handleDownload = () => {
    window.open(data.file, "_blank");
  };

  return (
    <div className="stvid-card">
      <div className="img-wrap">
        <img src={data.thumbnail || NoImage} />
      </div>
      <h4>{data.title}</h4>
      <p dangerouslySetInnerHTML={{ __html: data.description }} />
      {varIs("resource.fileType", data.file_type, "video") ? (
        <button className="play-btn" onClick={() => setModalMode("video")}>
          Watch Now
        </button>
      ) : (
        <button className="play-btn" onClick={handleDownload}>
          Download
        </button>
      )}
      {modalMode === "video" && (
        <VideoModal
          onCancel={() => setModalMode(undefined)}
          src={data.file.replace('https://vimeo.com', 'https://player.vimeo.com/video')}
          width={1200}
          isVimeo
        />
      )}
    </div>
  )
}

const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    margin: -16px -8px 0px -8px;
  }
  .tour-card {
    background: ${props=>props.theme.palette.cardBg};
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    padding: 15px;
    border-radius: 20px;
  }
  .video-wrap {
    width: 100%;
    position: relative;
    .thumb {
      width: 100%;
      border-radius: 15px;
    }
    .play-btn {
      width: 184px;
      height: 184px;
      position: absolute;
      left: calc(50% - 92px);
      top: calc(50% - 92px);
      cursor: pointer;
      &:hover {
        opactiy: 0.8;
      }
    }
  }
  .welcome-card {
    background: ${props=>props.theme.palette.cardBg};
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    padding: 23px 30px;
    border-radius: 20px;
    h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.1em;
      margin-bottom: 15px;
      color: ${props=>props.theme.palette.text};
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: ${props=>props.theme.palette.secondaryText};
      margin-bottom: 12px;
    }
    ul {
      color: ${props=>props.theme.palette.secondaryText};
      padding-left: 12px;
    }
  }
  .form-card {
    height: 100%;
    background: ${props=>props.theme.palette.cardBg};
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    padding: 20px;
    border-radius: 20px;
    h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.1em;
      margin-bottom: 15px;
      color: ${props=>props.theme.palette.text};
    }
    .agree-section {
      display: flex;
      flex-wrap: wrap;
    }
    .agree-wrap {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      color: ${props=>props.theme.palette.secondaryText};
      margin: 8px 0;
      width: 100%;
      cursor: pointer;
      svg {
        margin-right: 8px;
        font-size: 20px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .sign-wrap {
      margin: 15px 0;
      width: 590px;
      @media (max-width: 768px) {
        width: 290px;
      }
      .sigCanvas {
        border: 1px solid ${props=>props.theme.palette.cardBorder};
        border-radius: 8px;
      }
      .sign-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        label {
          color: ${props=>props.theme.palette.secondaryText};
        }
        button {
          border: 0 none;
          background: transparent;
          color: #c1954a;
        }
      }
    }
    .ant-input {
      border-radius: 10px;
      border: 1px solid ${props=>props.theme.palette.inputBorder};
      background: ${props=>props.theme.palette.inputBg};
      width: 100%;
      padding: 10px 15px;
      color: ${props=>props.theme.palette.text};
    }
    select {
      width: 100%;
      border: 1px solid ${props=>props.theme.palette.inputBorder};
      background: ${props=>props.theme.palette.inputBg};
      padding: 10px 15px;
      color: ${props=>props.theme.palette.text};
      border-radius: 10px;
      margin-left: 0;
      margin-right: 0;
    }
    .action-group {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 20px 0;
      .ant-btn {
        border-radius: 12px !important;
        background: ${props=>props.theme.palette.submitBtnBg};
        color: ${props=>props.theme.palette.submitBtnText};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        padding: 4px 34px;
        text-transform: capitalize;
        border: 0;
        cursor: pointer;
        height: 40px;
      }
    }
  }
  .account-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .label {
      margin-bottom: 15px;
    }
    .ant-checkbox + span {
      color: ${props=>props.theme.palette.text};
    }
    span.ant-radio + * {
      color: ${props=>props.theme.palette.text};
    }
    span.ant-radio.ant-radio-checked + * {
      color: ${props=>props.theme.palette.primary};
    }
    .ant-radio-wrapper {
      font-size: 1rem;
      margin-right: 36px;
    }
    p {
      color: ${props=>props.theme.palette.secondaryText};
    }
    height: 100%;
    .algo-wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 12px;
      p {
        padding-bottom: 15px;
      }
      h4 {
        font-style: normal;
        font-size: 14px;
        color: ${props=>props.theme.palette.text};
        margin: 0 12px 0 0;
      }
      .status-badge {
        background: rgba(185, 138, 66, 0.15);
        border-radius: 100px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #c1954a;
        padding: 4px 14px;
        margin-right: 12px;
        &.connected {
          color: #44c176;
          background: rgba(68, 193, 118, 0.15);
        }
        &.disconnected,
        &.copier_failed,
        &.connect_failed {
          color: #fa4661;
          background: rgba(250, 70, 97, 0.15);
        }
      }
      .fail-msg {
        font-size: 14px;
        color: ${props=>props.theme.palette.secondaryText};
      }
    }
  }
  .stvid-card {
    border-radius: 20px;
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    background: ${props=>props.theme.palette.cardBg};
    padding: 20px;
    .img-wrap {
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 14px;
      }
    }
    h4 {
      color: ${props=>props.theme.palette.text};
      font-size: 18px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    p {
      color: ${props=>props.theme.palette.secondaryText};
      font-size: 14px;
      line-height: 1.5em;
      height: 84px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .play-btn {
      border-radius: 8px !important;
      border: 1px solid ${props=>props.theme.palette.submitBtnBg};
      background: ${props=>props.theme.palette.submitBtnBg};
      color: ${props=>props.theme.palette.submitBtnText};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
      text-transform: capitalize;
      padding: 12px 34px;
    }
  }
`;
